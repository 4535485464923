exports.components = {
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-post-index-js": () => import("./../../../src/templates/post/index.js" /* webpackChunkName: "component---src-templates-post-index-js" */),
  "component---src-templates-service-index-js": () => import("./../../../src/templates/service/index.js" /* webpackChunkName: "component---src-templates-service-index-js" */)
}

